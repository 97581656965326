import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { inflect } from 'utils';

import Link from 'components/link';
import NavMain from 'components/nav/main';
import Flash from 'components/flash';
import Icon from 'components/icon';
import LogoTopLeft from 'components/logo';
import LoadingBar from 'components/loading/bar';
import { FadeIn, FadeInUpBounce } from 'components/transitions';
import Tooltip from 'components/tooltip';
import Help from 'components/tooltip/help';
import Modal from 'components/modal';
import Button from 'components/button';
import ButtonLink from 'components/button/link';
import MobileAlert from 'components/mobile-alert';
import Modals from './modals';
import TestModeIndicator from './test-mode-indicator';
import WindowWidth from 'utils/window-width';

import './main.scss';
import './print.scss';

export default class MainLayout extends React.Component {
  static contextTypes = {
    session: PropTypes.object,
    account: PropTypes.object,
    openIntercom: PropTypes.func,
    superAccess: PropTypes.func,
    toggleTestEnv: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showMenu: false,
      showHelp: false,
      showTrialBar: false,
      unreadMessageCount: 0,
      testMode: Boolean(props.testEnv),
    };

    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    // redirect to /admin ("live") route if client enabled a live environment
    if (
      this.props.account?.hasLiveEnvironment &&
      !prevProps.account?.hasLiveEnvironment
    ) {
      this.context.toggleTestEnv(false).then((success) => {
        success && this.setState({ testMode: false });
      });
    } else if (this.props.testEnv !== prevProps.testEnv) {
      this.setState({ testMode: Boolean(this.props.testEnv) });
    }
  }

  componentDidMount() {
    this.mounted = true;
    this.setPlanTrialFlag();
    document.addEventListener('click', this.onClickBody);
    setTimeout(() => {
      if (this.mounted) {
        window.Intercom &&
          window.Intercom('onUnreadCountChange', (unreadMessageCount) =>
            this.setState({ unreadMessageCount }),
          );
      }
    }, 1000);
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener('click', this.onClickBody);
  }

  onChangeTestEnv = (test) => {
    this.context.toggleTestEnv(test).then((success) => {
      success && this.setState({ testMode: test });
    });
  };

  setPlanTrialFlag() {
    const { account } = this.props;
    const { routes } = this.props.router;
    const targetRoute = routes[routes.length - 1];
    const { planTrial, planSelected, planTrialDaysLeft } = account;
    // show first if trial days < 12
    const show =
      targetRoute.trial !== false &&
      planTrial &&
      !planSelected &&
      planTrialDaysLeft < 12;
    try {
      if (show) {
        this.setState({ showTrialBar: true });
        if (planTrialDaysLeft > 2) {
          // auto hide after 15 seconds until 2 days left, then show always
          setTimeout(() => {
            if (this.mounted) {
              this.setState({ showTrialBar: false });
            }
          }, 15000);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  onClickBody = (event) => {
    if (
      !this.refs.helpButton?.contains(event.target) &&
      event.target.className !== 'layout-help-icon'
    ) {
      this.setState({ showMenu: false });
    }
  };

  onClickMenu = (event) => {
    event.preventDefault();
    this.setState({ showMenu: !this.state.showMenu });
  };

  onClickHelp = (event) => {
    event.preventDefault();
    this.setState({ showHelp: !this.state.showHelp });
  };

  onClickChat = (event) => {
    event.preventDefault();
    const { router } = this.props;
    const {
      account: { hasSupport },
      openIntercom,
    } = this.context;
    this.setState({ showMenu: false, showHelp: false });
    if (hasSupport) {
      openIntercom();
    } else {
      router.push('/settings/account/plans');
    }
  };

  renderHelpModal() {
    const {
      account: { hasSupport },
    } = this.props;
    return (
      <Modal width={800} cancel={false} onClose={this.onClickHelp} chromeless>
        <div className="layout-help-box">
          <div className="box">
            <div className="box-section">
              <h4 className="box-title">
                <Icon fa="comment-alt-dots" faType="light" /> &nbsp;Chat with us
              </h4>
              <div className="box-subtitle">
                {hasSupport ? (
                  <Fragment>Get help directly from the Swell team.</Fragment>
                ) : (
                  <Fragment>
                    <a href="" onClick={this.onClickChat}>
                      Upgrade your plan
                    </a>{' '}
                    to get help directly from the Swell team.
                  </Fragment>
                )}
                <Help message="Avg. response time: 1 minute" />
              </div>
              <div className="box-action">
                {hasSupport ? (
                  <Button size="sm" onClick={this.onClickChat}>
                    Chat now
                  </Button>
                ) : (
                  <ButtonLink
                    size="sm"
                    type="secondary"
                    onClick={this.onClickChat}
                  >
                    Upgrade plan
                  </ButtonLink>
                )}
              </div>
            </div>
            <div className="box-section">
              <h4 className="box-title">
                <Icon fa="comments" faType="light" /> &nbsp;Community support
              </h4>
              <div className="box-subtitle">
                Find answers and support others in the Swell community
              </div>
              <div className="box-action">
                <ButtonLink
                  size="sm"
                  href="https://github.com/orgs/swellstores/discussions"
                  target="blank"
                >
                  Visit discussions &rarr;
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { nav, flash, isModalOpened, account, children, location } =
      this.props;
    const {
      session,
      superAccess,
      account: { hasSupport, isExiled },
    } = this.context;

    const { unreadMessageCount, showMenu, showHelp, testMode } = this.state;

    const { routes } = this.props.router;
    const targetRoute = routes[routes.length - 1];
    const showingLayout = targetRoute.layout !== false;
    const showingLoader = targetRoute.loader !== false;
    const isOnSharedSettingsPage =
      location.pathname.includes('/settings/account') ||
      location.pathname.includes('/settings/users');

    return (
      <WindowWidth>
        {(windowWidth) =>
          windowWidth < 768 ? (
            <MobileAlert />
          ) : (
            <div>
              <div
                id="main"
                className={classNames('layout-container', {
                  'modal-open': isModalOpened,
                })}
              >
                <Flash {...flash} />

                {showingLoader && <LoadingBar />}

                {showingLayout ? (
                  targetRoute.layout === 'minimal' ? (
                    <Fragment>
                      <LogoTopLeft />

                      <div className="layout-content-minimal">{children}</div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <NavMain
                        nav={nav}
                        testMode={testMode}
                        onChangeTestEnv={this.onChangeTestEnv}
                        {...this.props}
                      />
                      <div className="layout-content">
                        {children}
                        {nav.editing && <div className="layout-nav-editing" />}
                      </div>
                    </Fragment>
                  )
                ) : (
                  children
                )}

                <FadeInUpBounce active={showMenu} className="layout-help-menu">
                  <a
                    href="#"
                    onClick={hasSupport ? this.onClickChat : this.onClickHelp}
                  >
                    <Icon fa="comment-alt-dots" faType="light" /> Get help
                  </a>

                  <hr />

                  <a href="https://support.swell.store/" target="blank">
                    Help center
                  </a>

                  <a
                    href="https://github.com/orgs/swellstores/discussions"
                    target="blank"
                  >
                    Community discussions
                  </a>

                  <a href="https://www.swell.is/changelog/" target="blank">
                    Changelog
                  </a>

                  <hr />

                  <a
                    href="https://www.swell.is/terms/"
                    target="blank"
                    className="note"
                  >
                    Terms &amp; privacy
                  </a>

                  <a
                    href="https://status.swell.store"
                    target="blank"
                    className="note"
                  >
                    Status
                  </a>
                </FadeInUpBounce>

                <a
                  href="#"
                  ref="helpButton"
                  className={classNames('layout-help', { showing: showMenu })}
                  onClick={this.onClickMenu}
                >
                  <Tooltip message={!showMenu && 'Get Help'} dir="left" delayed>
                    <span className="layout-help-icon" />
                  </Tooltip>

                  {unreadMessageCount > 0 && (
                    <span className="layout-help-unread-count">
                      {unreadMessageCount}
                    </span>
                  )}
                </a>

                {this.state.showTrialBar ? (
                  <FadeIn className="layout-plan">
                    Your free trial ends in{' '}
                    {inflect(account.planTrialDaysLeft, 'days')}
                    &nbsp;&nbsp;&nbsp;
                    <Link to="/settings/account">Pick a plan</Link>
                  </FadeIn>
                ) : (
                  isExiled &&
                  session.is_super_admin &&
                  session.super_access && (
                    <FadeIn className="layout-plan">
                      Viewing inactive account as super admin &nbsp;&nbsp;&nbsp;
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          superAccess(false);
                        }}
                      >
                        Exit
                      </a>
                    </FadeIn>
                  )
                )}

                {testMode && !isOnSharedSettingsPage && <TestModeIndicator />}

                <Modals />

                {showHelp && this.renderHelpModal()}
              </div>
            </div>
          )
        }
      </WindowWidth>
    );
  }
}
