import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

// First load the main styles
import './styles/global.scss';

import routes from './routes/instance';
import store from './store/instance';

const SENTRY_DSN = process.env.SENTRY_DSN;

const NODE_ENV = process.env.NODE_ENV;
const SWELL_VERSION = process.env.SWELL_VERSION;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: NODE_ENV,
    release: `client@${SWELL_VERSION}`,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    beforeSend(event, hint) {
      console.error(hint.originalException || hint.syntheticException);
      return event;
    },
  });
}

// Disable annoying deprecation React warnings
const originalWarn = console.warn;
console.warn = (...args) => {
  if (
    args.length > 0 &&
    typeof args[0] === 'string' &&
    (args[0].includes('componentWillMount has been renamed') ||
      args[0].includes('componentWillUpdate has been renamed') ||
      args[0].includes('componentWillReceiveProps has been renamed'))
  ) {
    return;
  }
  originalWarn(...args);
};

function App() {
  return (
    <Provider store={store}>
      <Router history={browserHistory} routes={routes} />
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
